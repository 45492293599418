import axios, { AxiosRequestHeaders, type AxiosError, type AxiosResponse } from 'axios'
import { InterceptedFetch } from './fetch'
import { refreshTokenInterceptors, refreshTokenInterceptorsFetch } from './refresh-token'
const { PROD } = import.meta.env
const { VITE_HTTP_PROXY } = window
const service = axios.create({
  baseURL: PROD ? `${VITE_HTTP_PROXY}` : '/api',
})

service.interceptors.request.use(
  (config) => {
    const _config = config
    const userstore = userStore()
    _config.headers = {
      ..._config.headers,
      Chattoken: userstore.userInfo.token || '',
    } as unknown as AxiosRequestHeaders
    return _config
  },
  (error: AxiosError) => {
    return Promise.reject(error.response)
  },
)

service.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    if (response.status === 200) {
      return response
    }
    throw new Error(response.status.toString())
  },
  (error) => {
    // refreshTokenInterceptors(service, error)
    return Promise.reject(error)
  },
)

export const ky: InterceptedFetch = InterceptedFetch.create({
  baseURL: PROD ? `${VITE_HTTP_PROXY}` : '/api',
})
ky.interceptors.request.use((config) => {
  const _config = config
  const userstore = userStore()
  _config.headers = {
    ..._config.headers,
    Chattoken: userstore.userInfo.token || '',
  } as unknown as AxiosRequestHeaders
  return _config
})
ky.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      // refreshTokenInterceptorsFetch(ky, response)
    }
    return response
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default service
