const getPluginTitle = (meta?: MetaData) => meta?.title
const getPluginDesc = (meta?: MetaData) => meta?.description

const getPluginTags = (meta?: MetaData) => meta?.tags
const getPluginAvatar = (meta?: MetaData) => meta?.avatar || '🧩'

const getPluginNameByIdentifier = (identifier: string) => {
  const [pluginName, pluginFunctionName] = identifier.split('__')
  return [pluginName, pluginFunctionName]
}

const isSettingSchemaNonEmpty = (schema?: PluginSchema) =>
  schema?.properties && Object.keys(schema.properties).length > 0

/**
 *
 * @description 获取多媒体消息
 * @param data
 */

const getMediaMessages = (data: any): MediaMsg[] => {
  const mediaMessage: any[] = []
  const types: ConversationType[] = ['pie_chart', 'bar_chart', 'line_chart', 'table', 'sql']
  types.forEach((type) => {
    if (data[type]) {
      mediaMessage.push({ type, data: data[type] })
    }
  })
  return mediaMessage
}
export {
  getPluginAvatar,
  getPluginDesc,
  getPluginTags,
  getPluginTitle,
  isSettingSchemaNonEmpty,
  getPluginNameByIdentifier,
  getMediaMessages,
}
/**
 *@description 数据库会话逻辑
 */
export type MediaMsg = {
  type: ConversationType
  data: any
}
