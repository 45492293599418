import { v4 as uuidv4 } from 'uuid'
import { fetchChatAPI } from '@/api'
import { userStore } from '@/store'
import { ChatHookParams, ChatParams, ChatHookFn } from '.'
import { isUnDef } from '@/utils'

/**
 *@description 数据库会话逻辑
 */
export type MediaMsg = {
  type: ConversationType
  data: any
}

export const useDBChat: ChatHookFn = ({
  isAbort,
  message,
  activeTopic,
  activeAgent,
  updateTopic,
  scrollToBottom,
}: ChatParams) => {
  const userstore = userStore()

  let controller = new AbortController()

  const activeIndex = ref<number | undefined>(undefined) //会话内容的索引, 默认是会后一条回答
  const isReGenerate = ref(false) // 是否重新生成
  /**
   * @description 发送(重试)会话信息
   * @param object {index: number} 重试回答是的信息所在索引
   */
  const sendMessage = async ({ index }: ChatHookParams = {}) => {
    isReGenerate.value = !isUnDef(index)
    controller = new AbortController()
    isAbort.value = false
    const len = activeTopic.value!.messages.length
    activeIndex.value = index ?? len - 1
    const messages = activeTopic.value!.messages[activeIndex.value]
    messages.status = 2
    try {
      await postMessage({ messages })
    } finally {
      isAbort.value = true
      scrollToBottom()
      updateTopic()
      // 如果用户没有勾选记住db，则提问回答后清除上下文所带的dbId
      if (activeTopic.value?.extra && !activeTopic.value?.extra?.remmeberDb?.length) {
        activeTopic.value.extra.dbId = ''
      }
    }
  }

  /**
   * @description 接口调用
   */
  const postMessage = async ({ messages }: { messages: IChatMessage }) => {
    let msg = toRaw(message.value)
    message.value = ''
    // 重试操作, 寻找对应的提问
    if (isReGenerate.value) {
      messages.mediaMessage = []
      const userMsg = activeTopic.value!.messages[activeIndex.value! - 1] || {}
      msg = userMsg?.role === 'userMessage' ? userMsg?.content || '' : ''
    }

    const signal = controller.signal

    let params: any = {}
    if (activeAgent.value.type === 'test-sql-chat') {
      params = {
        sessionId: activeTopic.value!.id,
        userId: activeAgent.value.pluginDetail
          ? JSON.parse(activeAgent.value.pluginDetail)[0].testUserId
          : userstore.userInfo.userId,
        question: msg,
        socketIOClientId: uuidv4(),
        history: [],
        chatFlowId: activeAgent.value?.agentId ?? '',
        dbId: activeAgent.value.pluginDetail
          ? JSON.parse(activeAgent.value.pluginDetail)[0].dbId
          : activeTopic.value?.extra?.dbId || '',
      }
    } else {
      params = {
        sessionId: activeTopic.value!.id,
        userId: userstore.userInfo.userId,
        question: msg,
        socketIOClientId: uuidv4(),
        history: [],
        chatFlowId: activeAgent.value?.agentId ?? '',
        dbId: activeTopic.value?.extra?.dbId || '',
      }
    }

    const res = await fetchChatAPI(params, signal)
    if (res.code === 1) {
      const data = res.data
      try {
        const text = JSON.parse(data.text)
        // JSON.parse(text)是数字时,抛错
        if (typeof text === 'number') {
          throw new Error('text is not number')
        }
        messages.content = text.thoughts
      } catch (error) {
        messages.content = data.text.toString()
      }
      // 添加其他多媒体信息(table, sql, chart)如果有的话
      const mediaMessages = getMediaMessages(data)
      messages.mediaMessage = mediaMessages
      messages.status = 0
      return
    }
    // 存储数据源信息
    activeTopic.value!.extra = {
      dbOptions: res.data || [],
    }
    messages.status = 1
    messages.content = res.msg
  }
  /**
   *
   * @description 获取多媒体消息
   * @param data
   */

  const getMediaMessages = (data: any): MediaMsg[] => {
    console.log(data, 'datadatadatdatatatdat')
    const mediaMessage: any[] = []
    const types: ConversationType[] = ['pie_chart', 'bar_chart', 'line_chart', 'table', 'sql']
    types.forEach((type) => {
      if (data[type]) {
        console.log(data[type], '666666666666666666666666666')
        mediaMessage.push({ type, data: data[type] })
      }
    })
    return mediaMessage
  }

  const stopStream = () => {
    isAbort.value = true
    controller?.abort()

    const len = activeTopic.value!.messages.length
    activeIndex.value = activeIndex.value || len - 1
    const messages = activeTopic.value!.messages[activeIndex.value]
    messages.status = 0
    !messages.content && (messages.content = '已手动中止回答')

    scrollToBottom()
    updateTopic()
  }
  return { sendMessage, stopStream, name: 'sql-chat' }
}
