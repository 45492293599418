import type { Ref } from 'vue'
import { nextTick, ref } from 'vue'
import { useEventListener } from '@vueuse/core'

type ScrollElement = HTMLDivElement | null

interface ScrollReturn {
  scrollRef: Ref<ScrollElement>
  isAtBottom: Ref<boolean>
  scrollToBottom: (isUserScrolled?: boolean, behavior?: ScrollBehavior | undefined) => void
  scrollToTop: () => void
  scrollToBottomIfAtBottom: () => void
}

export function useScroll(): ScrollReturn {
  const scrollRef = ref<ScrollElement>(null)

  // 是否滚动到了底部
  const isAtBottom = ref<boolean>(true)

  // 添加一个标志来检测用户是否手动滚动
  const userScrolled = ref(false)
  let scrollTimer: number | null = null
  const isDragging = ref(false)
  const handleScroll = () => {
    userScrolled.value = true
    // 添加一个定时器,在一段时间后重置 userScrolled
    if (scrollTimer !== null) {
      clearTimeout(scrollTimer)
    }
    scrollTimer = window.setTimeout(() => {
      userScrolled.value = false
    }, 5000)
  }
  // 添加滚动事件监听器
  onMounted(() => {
    useEventListener(scrollRef, 'wheel', handleScroll)
    useEventListener(scrollRef, 'touchmove', handleScroll)
    useEventListener(scrollRef, 'mousedown', () => (isDragging.value = true))
    useEventListener(document, 'mouseup', () => (isDragging.value = false))
    useEventListener(scrollRef, 'scroll', () => {
      if (isDragging.value) {
        handleScroll()
      }
    })
  })

  const bottonHandle = () => {
    if (scrollRef.value) {
      const $0 = scrollRef.value
      const scrollTop = $0.scrollTop
      const scrollHeight = $0.scrollHeight
      const clientHeight = $0.clientHeight
      isAtBottom.value = scrollTop + clientHeight >= scrollHeight - 10
    }
  }
  useEventListener(scrollRef, 'scroll', bottonHandle)

  const scrollToBottom = (isUserScrolled: boolean = true, behavior: ScrollBehavior | undefined) => {
    if (userScrolled.value && isUserScrolled) {
      return
    }
    nextTick(() => {
      if (scrollRef.value) {
        // scrollRef.value.scrollTop = scrollRef.value.scrollHeight
        scrollRef.value.scrollTo({
          top: scrollRef.value.scrollHeight,
          behavior,
        })
        isAtBottom.value = true
      }
    })
  }

  const scrollToTop = () => {
    nextTick(() => {
      if (scrollRef.value) {
        scrollRef.value.scrollTop = 0
      }
    })
  }

  const scrollToBottomIfAtBottom = () => {
    nextTick(() => {
      if (scrollRef.value) {
        // 阈值，表示到达滚动条底部的距离阈值。
        const threshold = 20
        const distanceToBottom =
          scrollRef.value.scrollHeight - scrollRef.value.scrollTop - scrollRef.value.clientHeight
        if (distanceToBottom >= threshold) {
          scrollRef.value.scrollTop = scrollRef.value.scrollHeight
        }
      }
    })
  }

  return {
    scrollRef,
    isAtBottom,
    scrollToBottom,
    scrollToTop,
    scrollToBottomIfAtBottom,
  }
}
