import { TopBar } from './TopBar'
import { AgentItem } from './AgentItem'
import { BottomBar } from './BottomBar'
import { debounce } from '@/utils/index'
import { Auth } from '@/permission/Auth'
import { getSubscribeChatFlow } from '@/api'
import { chatStore, userStore } from '@/store'
import { useResponsive } from '@/hooks/useResponsive'
import { cancelSubscribeChatFlow } from '@/api/market.ts'
import { CHAT_PROVIDER, ChatProvide } from '@/hooks/useTopic'
import { Skeleton } from '../ui/skeleton'
import { customerServiceAgent } from '@/contants/defaultAnget'
import { Identifier } from '@/contants/identifiers'

export const Sidebar = defineComponent({
  name: 'Sidebar',
  components: { TopBar },
  setup() {
    const userstore = userStore()
    const router = useRouter()
    const route = useRoute()
    const chatstore = chatStore()
    const keyword = ref('')
    const { isMobile } = useResponsive()
    const jumpPath = computed(() => {
      return isMobile.value ? '/chat-mobile' : '/chat'
    })

    const testAgent: IAgent[] = import.meta.env.PROD
      ? []
      : [
          {
            id: '49426bc2-b63b-444f-b6b2-6a9405c7be0f',
            name: 'DB-chat',
            type: 'db-chat',
            isStream: 2,
            isFileUpload: 2,
            description: 'AI驱动的智能SQL开发.数据探索, 快速数据查询🔥🔥🔥',
            avatar:
              'https://registry.npmmirror.com/@lobehub/assets-emoji/1.3.0/files/assets/man-technologist.webp',
          },
        ]
    const videoList: IAgent[] = [
      {
        id: '49426bc2-b63b-444f-b6b2-6a9405c7be01111',
        name: '执法记录仪',
        type: 'video-chat',
        isStream: 2,
        isFileUpload: 2,
        description: '可操作连接执法记录仪🔥',
        avatar:
          'https://registry.npmmirror.com/@lobehub/assets-emoji/1.3.0/files/assets/man-technologist.webp',
      },
    ]
    /**
     * @description 测试插件
     */
    const testPluginAgent: IAgent[] = [
      {
        id: route.query && route.query.pluginId ? route.query.session : '', //后面使用这个时通过route获取id
        type: 'test-plugin-chat',
        name: '插件测试',
        description: '用于测试配置的插件是否正常',
        tageDesc: '',
        avatar: '',
        isStream: 1,
        isFileUpload: 1,
        isPlugin: 1,
        pluginDetail:
          route.query && route.query.pluginId
            ? JSON.stringify([{ pluginId: route.query.pluginId, content: JSON.stringify('') }])
            : '',
      },
    ]

    const testSqlAgent: IAgent[] = [
      {
        id: route.query && route.query.dbId ? route.query.session : '', //后面使用这个时通过route获取id
        type: 'test-sql-chat',
        name: 'SQL对话测试',
        description: '用于测试配置的SQL注释是否正常',
        tageDesc: '',
        avatar: '',
        isFileUpload: 2,
        isPictureUpload: 2,
        isPlugin: 2,
        isPublic: 1,
        isPublish: 1,
        isPutOn: null,
        isStream: 2,
        pluginDetail:
          route.query && route.query.dbId
            ? JSON.stringify([
                {
                  dbId: route.query.dbId,
                  testUserId: route.query.testUserId,
                  content: JSON.stringify(''),
                },
              ])
            : '',
      },
    ]

    const agents = ref<IAgent[]>([])
    const agentsList = ref<IAgent[]>([...agents.value])
    const pagents = ref<IAgent[]>([])

    /**
     * @description 测试插件列表
     */
    const getAgentListByTestPlugin = () => {
      agents.value = testPluginAgent
      agentsList.value = testPluginAgent
      newChatFn(testPluginAgent[0])
    }

    /**
     * @description SQL对话测试列表
     *
     */
    const getAgentListByTestSql = () => {
      agents.value = testSqlAgent
      agentsList.value = testSqlAgent
      newChatFn(testSqlAgent[0])
    }

    /**
     * @description 添加聊天
     */
    async function newChat(agent: IAgent) {
      await chatstore.addChat(agent)
      router.push({
        path: jumpPath.value,
        query: {
          session: agent.id,
        },
      })
    }
    /**
     * @description 删除技能
     */
    const deleteAgent = (item: any) => {
      const data = {
        userId: userstore.userInfo.userId,
        chatFlowIdList: item.id.split(','),
      }
      cancelSubscribeChatFlow(data).then((res) => {
        if (res.code === 1) {
          item.isSubscribe = 2
          item.isPin = false
          window.$message?.success('移除成功')
          getSubscribeChatFlowHanlde()
          chatstore.resetActiveAgent()
          savePin(agentsList.value)
          // router.push('/market')
        } else {
          window.$message?.error(res.msg || '失败')
        }
      })
    }
    /**
     * @description 置顶技能, 移动到agentsList的第一位
     */
    const pinAgent = (anget: IAgent) => {
      if (anget.isPin) {
        localStorage.removeItem('sidebarSort')
        anget.isPin = false
        const topAgentsList = agentsList.value
          .filter((item) => {
            return item.isPin
          })
          .filter((item2) => {
            return item2.id !== anget.id
          })
        // console.log(topAgentsList, 'topAgentsListtopAgentsList')
        const pagentsList: IAgent[] = JSON.parse(JSON.stringify(pagents.value))
        // console.log(pagentsList, 'topAgentsListtopAgentsList')
        const list = pagentsList.filter((item) => {
          return !topAgentsList.some((item2) => {
            return item2.id === item.id
          })
        })
        const newSideBar = [...topAgentsList, ...list]
        agentsList.value = newSideBar
        savePin(agentsList.value)
        return
      }
      const index = agentsList.value.findIndex((item) => item.id === anget.id)
      if (index !== -1) {
        const item = agentsList.value.splice(index, 1)[0]
        item.isPin = true
        agentsList.value.unshift(item)
      }
      if (agentsList.value.length > 0) {
        savePin(agentsList.value)
        setSidebarList()
      }
    }

    const savePin = (sortArr: any) => {
      const sidebarSort = sortArr.map((item: any) => {
        const obj = {
          id: item.id,
          isPin: item.isPin,
        }
        return obj
      })
      localStorage.setItem('sidebarSort', JSON.stringify(sidebarSort))
    }

    /**
     * @description 获取订阅的技能
     */
    // const route = useRoute()
    const isLoading = ref(false)
    const getAgentList = () => {
      isLoading.value = true
      const data = {
        userId: userstore.userInfo.userId,
        name: keyword.value,
      }
      return getSubscribeChatFlow<IAgent[]>(data)
        .then(async (res) => {
          if (res.code === 1) {
            const data = res.data.map((item: IAgent) => {
              return {
                id: item.id,
                name: item.name,
                type: item.type,
                description: item.desc,
                tageDesc: item?.tageDesc,
                avatar: item.picturePath,
                isStream: item.isStream,
                isFileUpload: item.isFileUpload,
                isPlugin: item.isPlugin,
                pluginDetail: JSON.stringify(item.pluginDetail),
              }
            })
            agents.value = data
            agentsList.value = data
            if (data.length) {
              pagents.value = JSON.parse(JSON.stringify(data || []))
            }
            setSidebarList()
            return data
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    }
    const getSubscribeChatFlowHanlde = debounce(() => {
      if (userstore.userInfo.userId) {
        if (route.query && route.query.pluginId) {
          getAgentListByTestPlugin()
        } else if (route.query && route.query.dbId) {
          getAgentListByTestSql()
        } else {
          getAgentList()
        }
      }
    })
    getSubscribeChatFlowHanlde()

    window['getSubscribeChatFlowHanlde'] = getAgentList

    /**
     * @description 根据关键字 keyword.value 筛选agents技能
     */
    const searchAgent = () => {
      if (keyword.value) {
        agentsList.value = agents.value.filter((item) => {
          return item.name.includes(keyword.value)
        })
      } else {
        agentsList.value = agents.value
      }
    }

    const { cancleStream } = inject<ChatProvide>(CHAT_PROVIDER)!
    const newChatFn = (agent: IAgent) => {
      window.$useAuth(async () => {
        cancleStream()
        newChat(agent)
      })
    }

    const setSidebarList = () => {
      if (agentsList.value.length > 0) {
        const sidebarSort = localStorage.getItem('sidebarSort')
        if (sidebarSort) {
          const sort: Array<{ id: string; isPin?: boolean }> = JSON.parse(sidebarSort)
          const sortData = agentsList.value.sort((a, b) => {
            return (
              sort.findIndex((item) => {
                return item.id === a.id
              }) -
              sort.findIndex((item2) => {
                return item2.id === b.id
              })
            )
          })
          // console.log(sortData, 'sortData')
          const inPinData = sortData.map((item) => {
            sort.forEach((item2) => {
              if (item.id === item2.id) {
                item.isPin = item2.isPin
              }
            })
            return item
          })
          const arr1 = inPinData.filter((item) => item.isPin)
          const arr2 = inPinData.filter((item) => !item.isPin)
          agentsList.value = [...arr1, ...arr2]
        }
        // console.log(agentsList, 'agentsListagentsListagentsList')
      }
    }
    return () => (
      <div class='w-full h-full flex  flex-col bg-white dark:bg-neutral-900'>
        <TopBar
          v-model:value={keyword.value}
          onCreate={() => newChatFn(chatstore.initalAnget)}
          onInput={searchAgent}
        />

        {route.query && route.query.pluginId ? (
          <div class='flex flex-col flex-1 p-2 pb-0 overflow-hidden overflow-y-auto'>
            <div class='flex-1 overflow-y-auto'>
              <AgentItem
                showAction={false}
                item={testPluginAgent[0]}
                onClick={() => newChatFn(testPluginAgent[0])}
                onDelete={deleteAgent}
                onPin={pinAgent}
              />
            </div>
          </div>
        ) : (
          <div class='flex flex-col flex-1 p-2 pb-0 overflow-hidden overflow-y-auto'>
            <div class='flex-1 overflow-y-auto'>
              <AgentItem
                showAction={false}
                item={chatstore.initalAnget}
                onClick={() => newChatFn(chatstore.initalAnget)}
                onDelete={deleteAgent}
                onPin={pinAgent}
              />
              <Auth value={Identifier.HUMAN_SERVICE}>
                <div
                  class='w-full flex
              justify-between p-2 px-4 cursor-pointer
              mt-1 mb-1 rounded-md gap-2
              bg-gray-100
              dark:bg-neutral-800
            hover:bg-gray-100 dark:hover:bg-neutral-800'>
                  专家坐席
                </div>
                <AgentItem
                  showAction={false}
                  item={customerServiceAgent}
                  onClick={() => newChatFn(customerServiceAgent)}
                />
              </Auth>
              <div
                class='w-full flex
              justify-between p-2 px-4 cursor-pointer
              mt-1 mb-1 rounded-md gap-2
              bg-gray-100
              dark:bg-neutral-800
            hover:bg-gray-100 dark:hover:bg-neutral-800'>
                我的智能体
              </div>
              {isLoading.value ? (
                <div class={'flex flex-col gap-4 pl-4'}>
                  {new Array(5).fill(null).map((item) => (
                    <div class='flex items-center space-x-4' key={item}>
                      <Skeleton class='h-12 w-12 rounded-full' />
                      <div class='space-y-2'>
                        <Skeleton class='h-4 w-32' />
                        <Skeleton class='h-4 w-60' />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {videoList.map((item, index) => (
                    <AgentItem
                      item={item}
                      onClick={() => newChatFn(item)}
                      onDelete={deleteAgent}
                      onPin={pinAgent}
                      key={index}
                    />
                  ))}
                  {testAgent.map((item, index) => (
                    <AgentItem
                      item={item}
                      onClick={() => newChatFn(item)}
                      onDelete={deleteAgent}
                      onPin={pinAgent}
                      key={index}
                    />
                  ))}
                  {agentsList.value.map((item, index) => (
                    <AgentItem
                      item={item}
                      onClick={() => newChatFn(item)}
                      onDelete={deleteAgent}
                      onPin={pinAgent}
                      key={index}
                    />
                  ))}
                </>
              )}
            </div>
          </div>
        )}

        <div class='p-4 pt-2 lg:p-6 lg:pt-3 hidden sm:block'>
          <BottomBar />
        </div>
      </div>
    )
  },
})
