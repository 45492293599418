import { useWebNotification, useEventSource } from '@vueuse/core'
import { defineStore } from 'pinia'
import { getMessage as getMessageApi, getNotReadNum as getNotReadNumApi } from '@/api/notice'
import { ref } from 'vue'
const { PROD } = import.meta.env
export interface NoticeListState {
  id: string
  receiveIds: string
  receiveType: string
  sendUser: string
  sendTime: string
  sendTimeStr: string
  tittle: string
  content: any
  image?: any
  link?: any
  linkShow?: any
  attachments?: any
  contentType: string
  video?: any
  messageType: string
}
export const noticeStore = defineStore('noticeStore', () => {
  // 通知数量
  const noticeTotal = ref(0)
  // 通知列表

  const nl = ref<Array<NoticeListState>>([])

  // 获取历史消息
  const transData = (data) => {
    return data.map((item) => {
      const obj = {}
      const arrKey = ['images', 'videos', 'attachments']
      Object.keys(item).forEach((key) => {
        if (key === 'content') {
          if (item.contentType === 2) {
            obj[key] = JSON.parse(item[key])
          } else {
            obj[key] = item[key]
          }
        } else if (arrKey.includes(key)) {
          if (item[key]) {
            obj[key] = JSON.parse(item[key])
          } else {
            obj[key] = []
          }
        } else {
          obj[key] = item[key]
        }
      })
      return obj
    })
  }

  const curPage = {
    pageIndex: 1,
    pageSize: 10,
  }
  const messageLoading = ref(false)
  const notMore = ref(false)
  const getMessage = async () => {
    messageLoading.value = true
    const res: any = await getMessageApi(curPage)
    if (res.code === 1) {
      if (res.data.length) {
        nl.value.push(...transData(res.data))
      } else {
        notMore.value = true
      }
    } else {
      window.$message?.error(res.msg || '未知错误')
    }
    messageLoading.value = false
  }

  // 获取未读消息数量
  const getNotReadNum = async () => {
    const res: any = await getNotReadNumApi()
    if (res.code === 1) {
      noticeTotal.value = res.data
    } else {
      window.$message?.error(res.msg || '未知错误')
    }
  }
  // 建立sse链接
  let Es = reactive(null)
  const EsOneMessage = ref(false)
  const sseCnt = async (userId) => {
    nl.value = []
    // 获取历史消息
    getMessage()
    // 获取未读消息
    getNotReadNum()
    // 建立sse链接
    // const EsUrl = PROD
    //   ? `/llm/message/center/userConnect?userId=${userId}`
    //   : `/api/llm/message/center/userConnect?userId=${userId}`
    const EsUrl = PROD
      ? `/llm/message/center/userConnect?userId=${userId}`
      : `/api/llm/message/center/userConnect?userId=${userId}`
    Es = useEventSource(EsUrl, [], {
      autoReconnect: true,
    })
    Es.eventSource.value.addEventListener('open', function (e) {
      console.log(Es, 'open')
    })
    Es.eventSource.value.addEventListener('message', function (e) {
      if (e.data === 'success') {
        console.log('sse已连接')
      } else if (e.data) {
        EsOneMessage.value = true
        const data = JSON.parse(e.data)
        const dataArr = transData([data])
        nl.value.unshift(...dataArr)
        noticeTotal.value += 1
        OSMsg(data)
      }
    })
    Es.eventSource.value.addEventListener('error', function (e) {
      console.log('error')
    })
    Es.eventSource.value.addEventListener('close', function (e) {
      console.log(Es, '中断了')
    })
  }
  if (localStorage.getItem('_appChatSetting')) {
    const userInfo = localStorage.getItem('_appChatSetting')
    const user = JSON.parse(userInfo)
    if (user.userInfo.userId) {
      sseCnt(user.userInfo.userId)
    }
  }

  // 关闭sse链接
  const sseCls = () => {
    if (Es) {
      nl.value = []
      noticeTotal.value = 0
      Es.close()
      Es = null
    }
  }
  // 系统提示
  const OSMsg = (item) => {
    const { isSupported, notification, show, close, onClick, onShow, onError, onClose } =
      useWebNotification({
        title: '新通知-' + item.tittle || '新通知',
        dir: 'auto',
        lang: 'en',
        renotify: true,
        tag: 'test',
      })
    if (isSupported) {
      show()
    }
  }
  return {
    noticeTotal,
    nl,
    sseCnt,
    sseCls,
    OSMsg,
    getNotReadNum,
    curPage,
    getMessage,
    messageLoading,
    notMore,
    EsOneMessage,
  }
})
