import { v4 as uuidv4 } from 'uuid'
import { chatStore } from '@/store'
import { storeToRefs } from 'pinia'
import { UploadFileInfo } from 'naive-ui'
import { ChatHook } from '@/chat-strategies/hooks'
import { useUserInfo } from '@/hooks/useUserInfo'

export const CHAT_PROVIDER = Symbol('CHAT_PROVIDER')

export type ChatProvide = {
  message: Ref<string>
  isAbort: Ref<boolean>
  scrollRef: Ref<any>
  isAtBottom: Ref<boolean>
  addUserMessage: (params: {
    topicName?: string
    msg: string
    attachments?: UploadFileInfo[]
  }) => void
  addTipsMessage: (msg: string) => void
  addAIMessage: Fn
  activeAgent: Ref<ITopics | undefined>
  activeTopic: Ref<ITopic | undefined>
  createTopic: (str: string) => void
  updateTopic: Fn
  getTopicByName: (name: string) => ITopic | undefined
  deleteAllTopic: Fn
  deleteUnFavorite: Fn
  switchTopic: (topic: ITopic) => void
  deleteTopic: (topic: ITopic) => void
  favoriteTopic: (topic: ITopic) => void
  updateTopicName: (name: string) => void
  scrollToBottom: (isUserScrolled?: boolean, behavior?: ScrollBehavior | undefined) => void
  sendChatMessage: (params?: { topicName?: string; attachments?: UploadFileInfo[] }) => void
  activeChatHandle: Ref<ChatHook | undefined>
  cancleStream: Fn
}
export const useTopic = () => {
  const chatstore = chatStore()
  const { activeAgent, activeTopic, isAbort } = storeToRefs(chatstore)
  const { getQueryParams } = useUserInfo()

  onMounted(() => {
    chatstore.init().then(() => {
      nextTick(async () => {
        const params = getQueryParams()
        if (params.session as string) {
          // 重载页面时获取会话
          chatstore.getChatByAgentId(params.session as string)
        } else {
          await chatstore.getDefaultAgent()
          chatstore.setActiveAgentByUserId()
        }
        const chatList = activeAgent.value?.children || []
        activeTopic.value = chatList.find((item) => item.id === ((params.topic as string) || ''))
      })
    })
  })
  /**
   * @description 在临时会话框发送消息时，创建新会话(话题)
   * @param str 新会话的名称
   */
  const createTopic = (str?: string) => {
    if (!activeTopic.value?.id) {
      // 说明在默认话题上对话，则新增一个话题
      const topic: ITopic = {
        id: uuidv4(),
        topic: str || '新会话',
        lastUpdate: new Date().toString(),
        messages: [],
        extra: {},
      }
      // activeAgent.value?.children.splice(1, 0, topic)
      // 寻找最后一位收藏的话题的index
      const lastIndex = activeAgent.value?.children.findLastIndex((item) => item.isFavorite) || 0
      if (lastIndex !== -1) {
        activeAgent.value?.children.splice(lastIndex + 1, 0, topic)
      } else {
        activeAgent.value?.children.splice(1, 0, topic)
      }
      activeTopic.value = topic
    }
  }

  /**
   * @description 更新存储对话信息
   */
  const updateTopic = () => {
    chatstore.updateActiveAgent()
  }
  /**
   * @description 切换会话记录(话题)
   */
  const switchTopic = (topic: ITopic) => {
    activeTopic.value = topic
    // updateTopic()
  }

  /**
   * @description 根据name查询话题
   */
  const getTopicByName = (name: string) => {
    return activeAgent.value!.children.find((item) => item.name === name)
  }
  /**
   * @description 收藏话题
   */
  const favoriteTopic = (topic: ITopic) => {
    // activeTopic.value = topic
    const topics = activeAgent.value!.children || []
    if (topic.isFavorite) {
      // 收藏
      const index = topics.findIndex((item) => item.id === topic.id)
      if (index !== -1) {
        const item = topics.splice(index, 1)[0]
        topics.splice(1, 0, item)
      }
    } else {
      // 取消收藏
      const index = topics.findIndex((item) => item.id === topic.id)
      if (index !== -1) {
        const item = topics.splice(index, 1)[0]
        topics.splice(topics.length, 0, item)
      }
    }

    updateTopic()
  }
  /**
   * @description 更新会话名称
   */
  const updateTopicName = (name: string) => {
    activeTopic.value!.topic = name
    updateTopic()
  }
  /**
   * @description 删除会话(话题)
   */
  const deleteTopic = (topic: ITopic) => {
    if (activeTopic.value?.id) {
      window.$dialog?.error({
        title: '此操作将删除会话及记录, 请确认',
        positiveText: '确认',
        negativeText: '取消',
        onPositiveClick: () => {
          // 寻找activeAgent.value?.children中id为topic.id的索引
          const index = activeAgent.value?.children.findIndex((item) => item.id === topic.id)
          if (index! > -1) {
            activeAgent.value?.children.splice(index!, 1)
            if (activeTopic.value?.id === topic.id) {
              // 设置当前会话为默认会话
              activeTopic.value = activeAgent.value?.children.find((item) => item.id === '')
            }
            updateTopic()
          }
        },
      })
    }
  }
  /**
   * @description 删除所有会话
   */
  const deleteAllTopic = () => {
    activeAgent.value!.children.splice(1)
    activeTopic.value = activeAgent.value?.children.find((item) => !item.id)
    updateTopic()
  }
  /**
   * @description 删除未收藏会话
   */
  const deleteUnFavorite = () => {
    let lastIndex = activeAgent.value?.children.findLastIndex((item) => item.isFavorite) || 0
    lastIndex = lastIndex === -1 ? 0 : lastIndex
    activeAgent.value!.children.splice(lastIndex + 1)
    activeTopic.value = activeAgent.value?.children.find((item) => !item.id)
    updateTopic()
  }
  return {
    isAbort,
    activeAgent,
    activeTopic,
    createTopic,
    switchTopic,
    favoriteTopic,
    deleteTopic,
    updateTopic,
    getTopicByName,
    deleteAllTopic,
    deleteUnFavorite,
    updateTopicName,
  }
}
