/**
 * @description 拦截器fetch
 * @param config 请求配置
 * @example
 * // 创建实例
 * const http = InterceptedFetch.create()
 * http.interceptors.request.use(
  (config) => {
    console.log('请求拦截器');
    config.headers = {
      ...config.headers,
      'Authorization': 'Bearer token'
    };
    return config;
  },
  (error) => {
    console.error('请求拦截器错误:', error);
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    console.log('响应拦截器');
    return response;
  },
  (error) => {
    console.error('响应拦截器错误:', error);
    return Promise.reject(error);
  }
);

// 发起请求
http
  .get('https://api.example.com/data')
  .then((response) => response.json())
  .then((data) => console.log(data))
  .catch((error) => console.error(error))
 */
export class InterceptedFetch {
  private static instance: InterceptedFetch
  private baseURL: string
  private timeout: number
  private globalHeaders: Record<string, string>
  private requestInterceptors: Array<{ fulfilled: RequestInterceptor; rejected?: ErrorHandler }> =
    []
  private responseInterceptors: Array<{ fulfilled: ResponseInterceptor; rejected?: ErrorHandler }> =
    []

  constructor(config: InterceptedFetchConfig = {}) {
    this.baseURL = config.baseURL || ''
    this.timeout = config.timeout || 0
    this.globalHeaders = config.headers || {}
  }
  public static create(config?: InterceptedFetchConfig): InterceptedFetch {
    if (!InterceptedFetch.instance) {
      InterceptedFetch.instance = new InterceptedFetch(config)
    }
    return InterceptedFetch.instance
  }

  interceptors = {
    request: {
      use: (fulfilled: RequestInterceptor, rejected?: ErrorHandler) => {
        this.requestInterceptors.push({ fulfilled, rejected })
      },
    },
    response: {
      use: (fulfilled: ResponseInterceptor, rejected?: ErrorHandler) => {
        this.responseInterceptors.push({ fulfilled, rejected })
      },
    },
  }

  async request(url: string, config: RequestInit = {}): Promise<Response> {
    let currentConfig = {
      ...config,
      headers: {
        ...this.globalHeaders,
        ...config?.headers,
      },
    }

    // 应用请求拦截器
    for await (const interceptor of this.requestInterceptors) {
      try {
        const interceptedConfig = await interceptor.fulfilled(currentConfig)
        currentConfig = {
          ...currentConfig,
          ...interceptedConfig,
          headers: {
            ...currentConfig.headers,
            ...interceptedConfig.headers,
          },
        }
      } catch (error) {
        if (interceptor.rejected) {
          return interceptor.rejected(error)
        }
        throw error
      }
    }
    // url是一个完整的链接就不拼接
    const fullURL = /^https?:\/\//.test(url) ? url : this.baseURL + url

    try {
      // this.tieout > 0 时才设置超时
      let id: number | null = null
      if (this.timeout > 0) {
        const controller = new AbortController()
        id = window.setTimeout(() => controller.abort(), this.timeout)
        currentConfig.signal = controller.signal
      }
      let response = await fetch(fullURL, currentConfig)

      id && clearTimeout(id)

      // 将currentConfig添加到response对象中
      ;(response as Response & { config?: RequestInit }).config = currentConfig
      // 应用响应拦截器
      for (const interceptor of this.responseInterceptors) {
        try {
          response = await interceptor.fulfilled(response as Response & { config?: RequestInit })
        } catch (error) {
          if (interceptor.rejected) {
            return (await interceptor.rejected(error)) || response
          }
          throw error
        }
      }

      return response
    } catch (error) {
      console.error('Fetch Response Error:', error)
      throw error
    }
  }

  async get(url: string, config: RequestInit = {}): Promise<Response> {
    return this.request(url, { ...config, method: 'GET' })
  }

  async post(url: string, data?: any, config: RequestInit = {}): Promise<Response> {
    let body: string | FormData
    const headers: HeadersInit = {
      ...this.globalHeaders,
      ...config.headers,
    }

    if (data instanceof FormData) {
      body = data
      // 当使用FormData时,不设置Content-Type,让浏览器自动设置
      delete headers['Content-Type']
    } else {
      body = JSON.stringify(data)
      headers['Content-Type'] = 'application/json'
    }

    return this.request(url, {
      ...config,
      method: 'POST',
      body,
      headers,
    })
  }

  // 其他HTTP方法...
  async delete(url: string, config: RequestInit = {}): Promise<Response> {
    return this.request(url, { ...config, method: 'DELETE' })
  }
}
interface InterceptedFetchConfig {
  baseURL?: string
  timeout?: number
  headers?: Record<string, string>
}
type RequestInterceptor = (config: RequestInit) => RequestInit | Promise<RequestInit>
type ResponseInterceptor = (response: Response) => Response | Promise<Response>
type ErrorHandler = (error: any) => any
