import { get, post } from '@/utils/request'
/**
 * @description 获取消息
 */
export function getMessage<T = any>(data: { pageSize: number; pageIndex: number }) {
  return get<T>({
    url: '/llm/message/center/getMessage',
    data,
  })
}

// 获取未读消息数量

export function getNotReadNum<T = any>() {
  return get<T>({
    url: '/llm/message/center/getNotReadNum',
  })
}

// 设置已读
export function messageIsRead<T = any>() {
  return get<T>({
    url: '/llm/message/center/messageIsRead',
  })
}

// 获取系统信息
export function getAppSystemByUser<T = any>() {
  return get<T>({
    url: '/llm/pc/user/info/getAppSystemByUser',
  })
}

// 同步消息
export function syncUserInfo<T = any>(data: {
  systemId: string
  account: string
  password?: string
}) {
  return post<T>({
    url: '/llm/pc/user/info/syncUserInfo',
    data,
  })
}

// 取消同步消息
export function cancelSyncUserInfo<T = any>(data: { systemId: string }) {
  return get<T>({
    url: '/llm/pc/user/info/cancelSyncUserInfo?systemId=' + data.systemId,
  })
}
