import { v4 as uuidv4 } from 'uuid'
import { getVideoStream } from '@/api'
import { userStore } from '@/store'
import { ChatHookParams, ChatParams, ChatHookFn } from '.'

export const useVideoChat: ChatHookFn = ({
  isAbort,
  message,
  activeTopic,
  activeAgent,
  updateTopic,
  scrollToBottom,
}: ChatParams) => {
  const userstore = userStore()
  let controller = new AbortController()

  const sendMessage = async ({ index }: ChatHookParams = {}) => {
    controller = new AbortController()
    isAbort.value = false
    const len = activeTopic.value!.messages.length
    const activeIndex = index ?? len - 1
    const messages = activeTopic.value!.messages[activeIndex]
    messages.status = 2

    try {
      await postMessage({ messages })
    } finally {
      isAbort.value = true
      scrollToBottom()
      updateTopic()
    }
  }

  const postMessage = async ({ messages }: { messages: IChatMessage }) => {
    const msg = toRaw(message.value)
    message.value = ''
    // 预设问题列表
    const presetQuestions = ['连接酒泉1号摄像头', '截取当前图片', '聚焦摄像头']

    try {
      // 模拟接口异步返回
      await new Promise((resolve) => setTimeout(resolve, 2000))
      if (msg === '连接酒泉1号摄像头') {
        messages.content = '视频连成功'
      } else if (msg === '截取当前图片') {
        messages.content = '已截取当前图片'
        messages.mediaMessage = [
          {
            type: 'image',
            data: { devId: '' },
          },
        ]
      } else if (msg === '聚焦摄像头') {
        messages.content = '摄像头已聚焦'
      }
      messages.status = 0
    } catch (error) {
      messages.status = 1
      messages.content = error.message || '视频聊天请求失败'
    }
  }

  const stopStream = () => {
    isAbort.value = true
    controller?.abort()

    const len = activeTopic.value!.messages.length
    const messages = activeTopic.value!.messages[len - 1]
    messages.status = 0
    !messages.content && (messages.content = '已手动中止回答')

    scrollToBottom()
    updateTopic()
  }

  return { sendMessage, stopStream, name: 'video-chat' }
}
