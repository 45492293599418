<template>
  <n-form ref="formRef" :model="model" :rules="rules">
    <n-form-item path="systemId" label="系统">
      <n-spin class="w-full" :show="systemLoading">
        <n-select
          v-model:value="model.systemId"
          :on-update:value="systemChange"
          placeholder="请选择"
          :options="systemOptions"
        />
      </n-spin>
    </n-form-item>
    <n-form-item v-if="curSystem.tb === '未同步'" path="account" label="账号">
      <n-input v-model:value="model.account" placeholder="请输入" />
    </n-form-item>
    <n-form-item v-if="curSystem.tb === '未同步'" path="password" label="密码">
      <n-input v-model:value="model.password" type="password" placeholder="请输入" />
    </n-form-item>
    <n-form-item>
      <div class="flex w-full justify-end">
        <n-spin :show="submitLoading">
          <n-button v-if="curSystem.tb === '未同步'" type="primary" @click="submit('sync')"
            >同步</n-button
          >
          <n-button
            v-if="curSystem.tb === '已同步'"
            class="ml-3"
            type="error"
            @click="submit('async')"
            >取消同步</n-button
          >
        </n-spin>
      </div>
    </n-form-item>
  </n-form>
</template>

<script setup lang="ts">
import {
  getAppSystemByUser as getAppSystemByUserApi,
  syncUserInfo as syncUserInfoApi,
  cancelSyncUserInfo as cancelSyncUserInfoApi,
} from '@/api/notice'

const emit = defineEmits(['closeDialog'])

const formRef = ref(null)

const systemOptions = ref([])
const systemLoading = ref(false)

const getSystemOptions = async () => {
  systemLoading.value = true
  const res: any = await getAppSystemByUserApi()
  if (res.code === 1) {
    systemOptions.value = res.data.map((item: any) => {
      return {
        label: `${item.systemName} - [${item.tb}] - [${item.systemUserName || '无用户'}]`,
        tb: item.tb,
        value: item.systemId.toString(),
      }
    })
  } else {
    window.$message.error(res.msg || '未知错误')
  }
  systemLoading.value = false
}
getSystemOptions()
const curSystem: any = ref({})
const systemChange = (val) => {
  const fi = systemOptions.value.findIndex((item) => item.value == val)
  if (fi > -1) {
    curSystem.value = systemOptions.value[fi]
    model.value.systemId = val
  }
}

const model = ref({
  systemId: '',
  account: '',
  password: '',
})
const rules = ref({
  systemId: [
    {
      required: true,
      message: '请输入',
    },
  ],
  account: [
    {
      required: true,
      message: '请输入',
    },
  ],
})

const submitLoading = ref(false)
const submit = (type) => {
  formRef.value?.validate(async (errors) => {
    if (!errors) {
      submitLoading.value = true
      if (type === 'sync') {
        const res = await syncUserInfoApi({ ...model.value })
        if (res.code === 1) {
          window.$message.success('同步成功')
          getSystemOptions()
          // model.value.systemId = ''
          curSystem.value.tb = '已同步'
          model.value.account = ''
          model.value.password = ''
          // emit('closeDialog')
        } else {
          window.$message.error(res.msg || '未知错误')
        }
      } else if (type === 'async') {
        const res = await cancelSyncUserInfoApi({ systemId: model.value.systemId })
        if (res.code === 1) {
          window.$message.success('取消同步成功')
          getSystemOptions()
          // model.value.systemId = ''
          curSystem.value.tb = '未同步'
          model.value.account = ''
          model.value.password = ''
          // emit('closeDialog')
        } else {
          window.$message.error(res.msg || '未知错误')
        }
      }
      submitLoading.value = false
    }
  })
}
</script>

<style scoped lang="scss"></style>
